<template>
  <div class="fluid" id="assetList" v-if="permission.read_perm == 1">
    <div>
      <div class="asset-list-div">
        <v-card class="px-3 py-2" style="overflow:hidden;">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
                ASSET MANAGEMENT SYSTEM
              </h6>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="asset-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-toolbar-title>List Aset</v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <div v-if="isAdmin">
                    <div
                      v-if="permission.update_perm == 1"
                      style="width: 120px"
                    >
                      <v-select
                        :item-disabled="disableItem"
                        label="Action"
                        style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                        v-model="actionValue"
                        :items="[
                          { id: 0, name: '' },
                          { id: 1, name: 'Edit' },
                          { id: 8, name: 'Export Item Terpilih' },
                          { id: 9, name: 'Export Semua Item' },
                          /*{ id: 7, name: 'Generate QRCode' }*/
                          { id: 4, name: 'Delete' }
                          /* { id: 5, name: 'Hide' },
                          { id: 6, name: 'Stock report' }*/
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                        @change="action"
                      ></v-select>
                    </div>
                  </div>
                  <!-- <div v-if="isAdmin" style="width:200px;margin-right:10px;"> -->
                  <div style="width:200px;margin-right:10px;">
                    <v-select
                      v-model="paramAPI.company_id"
                      :items="dropDownCompany"
                      style="
                      position: relative;
                      top: 15px;
                      margin-left: 5px;
                      font-size: 12px;
                    "
                      label="kepemilikan"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                    ></v-select>
                  </div>
                  <div class="asset-list-toolbar-div">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="cari aset"
                      type="search"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      style="position: relative; top: 15px; width: 10px;
                        margin-left: 10px;"
                      @keyup.enter="searchEnter"
                    ></v-text-field>
                    <div
                      style="margin-left:20px;display:flex;justify-content:center;align-items:center;"
                    >
                      <v-btn
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px;"
                        @click="searchEnter"
                      >
                        search
                      </v-btn>
                    </div>
                  </div>
                </v-toolbar>
                <v-toolbar class="asset-list-toolbar-1" flat>
                  <div
                    style="display:flex;justify-content:flex-start;align-items:center;"
                  >
                    <router-link
                      to="/asset-management/aset-kepemilikan/tambah-aset"
                    >
                      <v-btn
                        :loading="loading"
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold"
                        style="font-size:12px;margin-right:20px;"
                      >
                        Tambah Aset
                      </v-btn>
                    </router-link>
                    <v-btn
                      v-if="this.isAdmin"
                      :loading="loading"
                      @click="sync_from_odoo"
                      outlined
                      small
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold"
                      style="font-size:12px;margin-right:20px;"
                    >
                      Sync odoo
                    </v-btn>
                  </div>
                  <div v-if="isAdmin" style="margin-right:auto;">
                    <div
                      v-if="permission.create_perm == 1"
                      style="display:flex;justify-content:center;align-items:center;"
                    >
                      <v-btn
                        @click.stop="importData"
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px; margin-right:10px;"
                      >
                        <v-icon size="20">mdi-file-import-outline</v-icon>
                        Import
                      </v-btn>
                      <!-- <v-btn
                        v-if="selectedData.length === 0"
                        @click.stop="fetchExport"
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px;"
                      >
                        <v-icon size="20" style="margin-right:5px;"
                          >mdi-export</v-icon
                        >
                        Export Semua
                      </v-btn>
                      <v-btn
                        v-else
                        @click.stop="exportItem"
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="green"
                        class="green--text"
                        style="font-size:12px; margin-right:10px;"
                      >
                        <v-icon size="20" style="margin-right:5px;"
                          >mdi-export</v-icon
                        >
                        Export Now
                      </v-btn>
                      <v-btn
                        v-if="selectedData.length !== 0"
                        @click.stop="cancelExport"
                        small
                        outlined
                        rounded
                        elevation="1"
                        color="red"
                        class="red--text"
                        style="font-size:12px;"
                      >
                        <v-icon size="20">mdi-close</v-icon>
                      </v-btn> -->
                    </div>
                  </div>
                </v-toolbar>
              </div>
              <v-toolbar
                class="asset-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12" style="overflow:auto;">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="55vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer;min-width:1200px;"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :show-select="permission.update_perm == 1 && isAdmin"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.category.name }}
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row;"
                  >
                    {{ item.name }}
                  </div>
                </template>
              </v-data-table>

              <div style="height:0px;overflow:hidden;">
                <!-- <AssetAdd
                  :param="{
                    items: itemEdit
                  }"
                  @loadPage="getDataFromApi"
                  :permission="permission"
                  :isAdmin="isAdmin"
                /> -->
                <!-- <DetailAsset
                  :param="{
                    items: itemEdit
                  }"
                  @loadPage="getDataFromApi"
                  :permission="permission"
                /> -->
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <div style="position:fixed; bottom:10px; right:0;">
      <Scanner />
    </div>
    <AssetImport @loadPage="getDataFromApi" :permission="permission" />
    <v-card style="width:0; height:0; overflow:hidden;">
      <vue-html2pdf
        shrink-to-page
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="false"
        filename="hehehe"
        :paginate-elements-by-height="1100"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="290px"
        :manual-pagination="false"
        @beforeDownload="beforeDownload($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div v-for="(item, index) in selectedData" :key="index">
            <section class="pdf-item">
              <h4>
                Title
              </h4>

              <span>
                Value
              </span>
            </section>

            <div class="html2pdf__page-break" />
          </div>
        </section>
      </vue-html2pdf>
    </v-card>
    <v-dialog v-model="qrDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h5">
          Print Settings
        </v-card-title>

        <v-card-text>
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            outlined
            style="width:100%;"
            color="primary darken-1"
            text
            @click.prevent="generate"
          >
            Generate Now
          </v-btn>

          <v-btn
            style="position:absolute; top:5px; right:5px;"
            small
            icon
            color="red darken-1"
            text
            @click="closeQrDialog"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <div
          v-if="loading"
          style="position: absolute; top:0; left:0; width:100%; height:100%; background:rgba(255,255,255);"
        >
          <v-row
            no-gutters
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              class="text-subtitle-1 text-center"
              style="padding: 0 20px; margin-bottom:10px;"
              cols="12"
            >
              Please wait, Getting your files ready
            </v-col>
            <v-col cols="8">
              <v-progress-linear
                color="primary accent-4"
                indeterminate
                rounded
                height="6"
                style="border:1px solid grey; width:100%;"
              >
              </v-progress-linear>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" width="500" persistent>
      <v-form
        v-if="itemEdit !== null"
        :disabled="loading"
        ref="entryForm"
        @submit.prevent="submitEdit"
        style="position: relative;"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Edit Asset
          </v-card-title>

          <v-card-text class="d-flex justify-center align-center">
            <v-row no-gutters>
              <v-col md="12" style="padding: 0; position:relative; top:20px;">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kode
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field v-model="itemEdit.code" outlined />
                  </v-col>
                </div>
              </v-col>
              <v-col md="12" style="padding: 0; position:relative; top:20px;">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Nama
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field v-model="itemEdit.name" outlined />
                  </v-col>
                </div>
              </v-col>
              <v-col md="12" style="padding: 0; position:relative; top:20px;">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Company
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-select
                      dense
                      v-model="itemEdit.company_id"
                      :items="dropDownCompany"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      clearable
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="error"
              text
              outlined
              @click="closeEdit"
              :loading="loading"
            >
              Batal
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              text
              outlined
              :loading="loading"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import XLSX from 'xlsx'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: {
    // AssetAdd: () => import('./components/FormAdd'),
    // DetailAsset: () => import('./components/Detail'),
    AssetImport: () => import('./components/Import.vue'),
    Scanner: () => import('../../home/components/Scanner.vue'),
    VueHtml2pdf
  },
  data: () => ({
    dialog: false,
    qrDialog: false,
    editDialog: false,
    asset: buildType.apiURL('asset'),
    // build: process.env.VUE_APP_BUILD_TYPE,
    isAdmin: false,
    menu_key: 42,
    permission: {},
    application: {
      id: 18,
      name: 'e-Asset Management System'
    },
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      sortBy: 'name',
      sortType: 'asc',
      company_id: 0,
      dept_id: '',
      itemsPerPage: 10
    },
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'Nama Aset',
        value: 'name',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Kode Aset',
        value: 'code',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Merk',
        value: 'merk',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Kategori',
        value: 'category',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Total Stock',
        value: 'total_stock',
        align: 'left',
        sortable: false
        // class: 'black--text'
      },
      {
        text: 'Stock terakhir',
        value: 'current_stock',
        align: 'left',
        sortable: false
        // class: 'black--text'
      }
      // {
      //   text: '',
      //   value: 'action',
      //   align: 'left',
      //   sortable: false
      //   // class: 'blue--text'
      // }
    ],
    company: [],
    department: [],
    result: [],
    selectedData: [],
    dropDownCompany: [],
    loading: false,
    itemEdit: null,
    detail: null
  }),

  watch: {
    // 'options.page'() {
    //   this.paramAPI.offset = this.options.page - 1
    //   this.getDataFromApi()
    // }
    'paramAPI.company_id'() {
      this.$store.commit('setParameter', this.paramAPI.company_id)
      this.result = []
      this.totalData = 0
      this.paramAPI.page = 1
      this.options.page = 1
      this.paramAPI.keyword = ''
      this.paramAPI.offset = 0
      this.paramAPI.limit = 10
      this.getDataFromApi()
    }
  },
  mounted() {},
  created() {
    // a = approver_id | b = admin_id
    // const a = this.build === 'development' ? '19' : '20'
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            if (arr[i].id === '1' || arr[i].id === '29') {
              this.isAdmin = true
            } else {
              this.isAdmin = false
            }

            if (this.$store.state.asset.parameter.plant_id === null) {
              this.paramAPI.company_id = this.getUserProfile.employee.company.plant_id
            } else {
              this.paramAPI.company_id = this.$store.state.asset.parameter.plant_id
            }

            this.permission = result.data
            this.setPermission(result.data)
            // this.initDataTable()
            this.getDataFromApi()
            this.setAction()
            this.initDropdown()
          })
          .catch(error => {
            if (error) {
              this.isAdmin = false
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
            }
          })
      } else {
        this.isAdmin = false
        this.permission = {
          read_perm: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUserProfile']) /* 'getDropdownPlant' */
  },
  methods: {
    ...mapMutations(['setPermission', 'setParamAsset']),
    ...mapActions(['menu_access', 'dropdownPlant']),

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    async initDropdown() {
      // universal dropdown
      await this.dropdownPlant()
      const application = this.getUserProfile.level
      const result = application.find(({ id }) => id === '1' || id === '29')
      console.log(result, this.getUserProfile.employee)
      if (result !== undefined) {
        const company = [
          { id: 1, name: 'TMP' },
          { id: 2, name: 'IMP' },
          { id: 3, name: 'GIT' },
          { id: 4, name: 'KAJ' },
          { id: 5, name: 'WIM' },
          { id: 6, name: 'SMI' },
          { id: 7, name: 'THP' }
        ]
        if (application.find(({ id }) => id === '1') !== undefined) {
          return (this.dropDownCompany = company)
        } else if (
          application.find(({ id }) => id === '29') !== undefined &&
          this.getUserProfile.employee.company.plant_id === '6'
        ) {
          return (this.dropDownCompany = company)
        }
      }
      return (this.dropDownCompany = [
        {
          id: this.getUserProfile.employee.company.plant_id,
          name: this.getUserProfile.employee.company.name
        }
      ])

      // this.dropdownCompany()
    },
    disableItem(item) {
      if (item.id !== 9) {
        return this.selectedData.length === 0
      }
      if (item.id === 9) {
        return this.selectedData.length !== 0
      }
    },
    action(event) {
      switch (event) {
        case 1:
          if (this.permission.update_perm === 1) {
            this.itemEdit = this.selectedData[0]
            setTimeout(() => {
              console.log(this.itemEdit)
              this.editDialog = true
            }, 500)
          }
          break
        case 4:
          if (this.permission.delete_perm === 1) {
            if (this.selectedData.length < 2) {
              this.delete()
            } else {
              this.showMsgDialog(
                'warning',
                'Maaf, untuk saat ini Anda hanya dapat menghapus 1 data pada satu waktu',
                false
              )
              setTimeout(() => {
                this.selectedData = []
                this.actionValue = 0
              }, 200)
            }
          }
          break
        // case 5:
        //   if (this.permission.delete_perm === 1) {
        //     this.hide()
        //   }
        //   break
        // case 7:
        //   this.printAsset()
        //   break
        case 8:
          this.exportItem()
          break
        case 9:
          this.exportItem()
          break
      }
    },
    searchEnter() {
      this.getDataFromApi()
    },
    setAction() {
      this.setParamAsset(null)
      // this.itemEdit = null
      this.actionValue = 0
      this.selectedData = []
    },

    getDataFromApi() {
      this.loading = true
      this.initDataTable().then(data => {
        this.result = data.data
        this.totalData = data.total_record
        this.loading = false
      })
    },
    initDataTable() {
      const apiLink = this.isAdmin
        ? '&company_id=' + this.paramAPI.company_id
        : ''
      return new Promise(resolve => {
        axios
          .get(
            `${this.asset}asset/list?keyword=${
              this.paramAPI.keyword
            }&order_by=${this.paramAPI.sortBy}&order_type=${
              this.paramAPI.sortType
            }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
              this.paramAPI.limit
            }${apiLink}`
          )
          .then(res => {
            // console.log(res.data)
            // this.result = res.data.data
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    add() {
      document.getElementById('assetAdd').click()
      // axios
      //   .get(`${this.asset}asset/sync_from_catalogue`)
      //   .then(res => {
      //     this.showMsgDialog('info', res.data.message, false)
      //     this.initDataTable()
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    },
    rowClick(pItem) {
      // this.itemEdit = pItem
      // setTimeout(() => {
      //   document.getElementById('assetDetail').click()
      // }, 500)
      this.setParamAsset(pItem)
      setTimeout(() => {
        this.$router.push('/asset-management/aset-kepemilikan/detail')
      }, 300)
    },
    delete() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(res => {
        if (res.isConfirmed) {
          axios
            .delete(`${this.asset}asset/delete/${this.selectedData[0].id}`)
            .then(res => {
              this.actionValue = 0
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.getDataFromApi()
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    hide() {
      // this.showMsgDialog('question', 'Hide Now', true).then(res => {
      //   if (res.isConfirmed) {
      //     axios
      //       .put(
      //         `${this.eSanqua}archive_document/archive/${this.selectedData[0].id}`,
      //         { is_delete: 1 }
      //       )
      //       .then(res => {
      //         this.showMsgDialog('success', res.data.status_msg, 'false')
      //         this.getDataFromApi()
      //       })
      //       .catch(err => {
      //         console.log(err)
      //       })
      //   } else {
      //     this.actionValue = 0
      //   }
      // })
    },
    clear() {
      this.detail = null
    },
    updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      this.getDataFromApi()
    },
    updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      this.getDataFromApi()
    },
    sync_from_odoo() {
      this.showMsgDialog('question', 'Sync now ?', true).then(res => {
        if (res.isConfirmed) {
          this.loading = true
          axios
            .post(
              `${this.asset}asset/sync_from_odoo?company_id=${this.paramAPI.company_id}`
            )
            .then(res => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', res.data.status_msg, 'false')
              } else {
                this.showMsgDialog('warning', res.data.status_msg, 'false')
              }
              this.getDataFromApi()

              this.loading = false
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        }
      })
    },
    importData() {
      setTimeout(() => {
        document.getElementById('assetImport').click()
      }, 200)
    },
    exportItem() {
      this.showMsgDialog('question', 'Export sekarang', true).then(res => {
        if (res.isConfirmed) {
          this.checkLotId()
        } else if (res.dismiss === 'cancel') {
          this.setAction()
        } else if (res.dismiss === 'backdrop') {
          // this.selectedData = []
          this.setAction()
        }
      })
    },
    checkLotId() {
      const arrId = []
      for (let i = 0; i < this.selectedData.length; i++) {
        arrId.push(this.selectedData[i].clear_id)
      }
      this.fetchExport(arrId)
    },
    async fetchExport(arr) {
      this.loading = true
      this.qrDialog = true
      const setParam = arr.length > 0 ? '&id=[' + arr + ']' : ''
      await axios
        .get(
          `${this.asset}asset/lot/export?company_id=${this.paramAPI.company_id}${setParam}`
        )
        .then(res => {
          console.log(res)
          this.selectedData = []
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              this.selectedData.push({
                id: response[i].id,
                name: response[i].name,
                lot_no: response[i].lot_no,
                department_id: response[i].department_id,
                department_name: response[i].department_name
              })
            }
            this.exportNow()
          } else {
            this.selectedData = []
          }
          return null
        })
        .catch(error => {
          console.log(error)
          return null
        })
    },
    exportNow() {
      const arrData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        const param = {
          id: this.selectedData[i].id,
          lot_no: this.selectedData[i].lot_no,
          name: this.selectedData[i].name,
          department_id: this.selectedData[i].department_id,
          department_name: this.selectedData[i].department_name
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    cancelExport() {
      this.selectedData = []
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'AssetList.xlsx')

      setTimeout(() => {
        this.closeQrDialog()
        this.loading = false
      }, 1000)
    },
    printAsset() {
      setTimeout(() => {
        this.qrDialog = true
      }, 200)
    },
    closeQrDialog() {
      this.qrDialog = false
      this.selectedData = []
      this.actionValue = 0
    },
    generate() {
      this.$refs.html2Pdf.generatePdf()
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      this.loading = true
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get('pdf')
        .then(pdf => {})
        .save()
      setTimeout(() => {
        this.loading = false
        this.qrDialog = false
        this.selectedData = []
        this.actionValue = 0
      }, 1000)
    },

    closeEdit() {
      this.itemEdit = null
      this.editDialog = false
      this.actionValue = 0
      this.selectedData = []
    },
    submitEdit() {
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      // const id = this.itemEdit.name.slice(1, this.itemEdit.name.indexOf('.'))

      const name = this.getCompany(Number(this.itemEdit.company_id))
      const form = {
        act: 'update_by_id',
        id: this.itemEdit.id,
        code: this.itemEdit.code,
        name: this.itemEdit.name,
        category_id: this.itemEdit.category.id,
        category_name: this.itemEdit.category.name,
        unit_id: this.itemEdit.unit.id,
        unit_name: this.itemEdit.unit.name,
        company_id: this.itemEdit.company_id,
        // company_id: Number(id),
        company_name: name,
        merk: ''
      }
      console.log(form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.asset}asset/save`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDataFromApi()
            this.closeEdit()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    getCompany(val) {
      switch (val) {
        case 1:
          return 'PT TIRTA MAS PERKASA (TMP)'
        case 2:
          return 'PT INDOMULTIMAS PERKASA (IMP)'
        case 3:
          return 'PT GRAHAMAS INTITIRTA (GIT)'
        case 4:
          return 'PT KENCANA ABADI JAYA (KAJ)'
        case 5:
          return 'PT WAHANA INTI MAS (WIM)'
        case 6:
          return 'PT SANQUA MULTI INTERNASIONAL (SMI)'
        default:
          return null
      }
    }
  }
}
</script>
<style lang="scss">
#assetList {
  position: relative;
  .asset-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .asset-list-toolbar-1 {
      .asset-list-toolbar-div {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .asset-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #assetList {
    .asset-list-div {
      .asset-list-toolbar-1 {
        .asset-list-toolbar-div {
          display: none;
        }
      }
      .asset-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
